import React from "react";
import { Typography } from 'antd';


const { Text } = Typography

export const PoweredBy = () => {

    const MONNER = [
        'MONSTER ENERGY','MONSTER THE DOCTOR','MONSTER ASSAULT','MONSTER LH44',
        'MONSTER ULTRA CITRON','MONSTER ULTRA VIOLET','MONSTER ULTRA PARADISE',
        'MONSTER KHAOS','MONSTER MANGO LOCO','MONSTER PIPELINE PUNCH','MONSTER MIXXD',
    ]

    return (
        <Text className="poweredby">Powered by {MONNER[Math.floor(Math.random()*MONNER.length)]}</Text>
    )
}