import React from 'react';
import { Layout, Image } from 'antd';
import { Lottie } from '@crello/react-lottie';
import GoogleButton from 'react-google-button'
import 'roboto-mono-webfont';
import animationData from '~/assets/animations/cooking.json'
import { signInWithGoogle } from '~/services/auth';
import {
    TopNav,
    PoweredBy,
} from "~/components";


const { Header, Footer, Content } = Layout;

export const SignIn = () => {
    return (
        <Layout>
            <Header>
                <TopNav />
            </Header>
            <Content style={{padding: '0 50px'}}>
                <div id="signin">
                    <Image src={require('~/assets/img/logo.png')} />
                    <Lottie
                        style={{margin: '0 auto'}}
                        width="375px"
                        height="216px"
                        config={{
                            animationData: animationData,
                            loop: true,
                            autoplay: true,
                        }}
                    />
                    <GoogleButton
                        className="login-button"
                        type="light"
                        onClick={signInWithGoogle}
                    />
                </div>
            </Content>
            <Footer>
                <PoweredBy />
            </Footer>
        </Layout>
    )
}