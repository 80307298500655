import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Layout, Typography, DatePicker, Button, PageHeader, Row, Col, Table, Progress } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { approvedUsersCount, User } from '~/services/users';
import { getRegistrations, Registration } from '~/services/registrations';
import {
    TopNav,
    PoweredBy,
    DayList,
    DayListItem
} from "~/components";
import moment, {Moment} from 'moment';
require('moment/locale/da');


const { Header, Footer, Content } = Layout
const { Text } = Typography

interface TParams {
    week: string
}

type ParticipantListItem = {
    key: string
    name: string
    updated: Moment
}

export const Registrations = () => {
    const { week } = useParams<TParams>()
    const history = useHistory()

    const [selectedWeek, setSelectedWeek] = useState(moment(week).startOf('week'))

    const [usersCount, setUsersCount] = useState<number>(0)
    const [registrations, setRegistrations] = useState<Registration[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
    const [participants, setParticipants] = useState<ParticipantListItem[]>([])

    const loadRegistrations = (date: Moment) => {
        setIsLoading(true)
        getRegistrations(date).then((newRegistrations: Registration[]) => {
            setRegistrations(newRegistrations)
            setParticipants([])
            setIsLoading(false)
        })
    }

    useEffect(() => {
        approvedUsersCount().then(count => {
            setUsersCount(count)
        })
    }, [])

    useEffect(() => {
        setSelectedIndex(undefined)
        loadRegistrations(selectedWeek)

    }, [selectedWeek])

    useEffect(() => {
        if (typeof selectedIndex !== 'undefined' && registrations.length > 0) {
            let key: string = ['monday','tuesday','wednesday','thursday','friday'][selectedIndex]
            let items: ParticipantListItem[] = []
            let regs = registrations.filter(r => r.days[key])
            regs.map(r => {
                items.push({
                    key: r.uid,
                    name: r.user?.name || '-',
                    updated: r.updated,
                })
            })
            setParticipants(items)
        } else {
            setParticipants([])
        }
    }, [selectedIndex])

    const handleDateChange = (date: Moment | null) => {
        let week = moment(date).startOf('week')
        setSelectedWeek(week)
        history.push(`/registrations/${moment(week).format('YYYY-MM-DD')}`)
    }

    // setup table columns
    const columns = [
        {
            title: 'Navn',
            key: 'name',
            sorter: (a: User, b: User) => a.name.localeCompare(b.name),
            render: (text: string, record: ParticipantListItem) => <Text>{record.name}</Text>
        },
        {
            title: 'Dato',
            key: 'date',
            defaultSortOrder: 'ascend',
            sorter: (a: User, b: User) => moment(a.updated).unix() - moment(b.updated).unix(),
            render: (text: string, record: ParticipantListItem) => <Text>{moment(record.updated).format('LLL')}</Text>,
        },
    ]

    const percent = (usersCount > 0 ? Math.floor(Math.min(100, ((100 / usersCount) * registrations.length))) : 0)

    return (
        <Layout>
            <Header>
                <TopNav />
            </Header>
            <Content style={{padding: '0 50px'}}>
                <div id="registrations">
                    <PageHeader
                        title={'Uge ' + moment(selectedWeek).isoWeek()}
                        subTitle={moment(selectedWeek).format('D. MMM') + ' - ' + moment(selectedWeek).add(6, 'days').format('D. MMM')}
                        extra={[
                            <Button
                                key="previousweek"
                                type="primary"
                                shape="circle"
                                disabled={isLoading}
                                onClick={() => handleDateChange(moment(selectedWeek).subtract(1, 'week'))}>
                                <LeftOutlined />
                            </Button>,
                            <Button
                                key="nextweek"
                                type="primary"
                                shape="circle"
                                disabled={isLoading}
                                onClick={() => handleDateChange(moment(selectedWeek).add(1, 'week'))}>
                                <RightOutlined />
                            </Button>,
                            <DatePicker
                                key="selectedweek"
                                disabled={isLoading}
                                picker="week"
                                value={selectedWeek}
                                allowClear={false}
                                onChange={(date) => handleDateChange(date)}
                            />,
                        ]}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <DayList
                                    loading={isLoading}
                                    selectedWeek={selectedWeek}
                                    selectedIndex={selectedIndex}
                                    registrations={registrations}
                                    onClick={(day: DayListItem, index: number) => {
                                        setSelectedIndex(index)
                                    }}
                                />
                                <Progress
                                    percent={percent}
                                    format={percent => `${registrations.length}/${usersCount || 0}`}
                                    showInfo={usersCount > 0}
                                />
                            </Col>
                            <Col span={12}>
                                {typeof selectedIndex !== 'undefined' ? (
                                    <Table
                                        loading={isLoading}
                                        columns={columns}
                                        dataSource={participants}
                                        pagination={false}
                                        locale={{emptyText: 'Ingen tilmeldinger'}}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </PageHeader>
                </div>
            </Content>
            <Footer>
                <PoweredBy />
            </Footer>
        </Layout>
    )
}