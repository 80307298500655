import React, {FC} from "react";
import { Table, Tooltip, Typography, Row, Col, Space } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import moment, {Moment} from 'moment';
require('moment/locale/da');
import { Registration } from '~/services/registrations';


const { Text } = Typography

interface DayListProps {
    loading: boolean
    selectedWeek: Moment
    selectedIndex: number | undefined
    registrations: Registration[]
    onClick?: (record: DayListItem, index?: number | undefined) => void
}

export type DayListItem = {
    key: string
    date: Moment
    day: string
    floor: number[]
    total: number
}

type Days = {
    [ index: string ] : {
        total: number
        floor: number[]
    }
    monday: {
        total: number
        floor: number[]
    }
    tuesday: {
        total: number
        floor: number[]
    }
    wednesday: {
        total: number
        floor: number[]
    }
    thursday: {
        total: number
        floor: number[]
    }
    friday: {
        total: number
        floor: number[]
    }
}

/**
 * This displays a Table with registrations summed into: day, total
 */
export const DayList: FC<DayListProps> = ({loading, selectedWeek, selectedIndex, registrations, onClick}) => {

    // days with sum
    let days: Days = {
        monday: {
            total: 0,
            floor: [0,0],
        },
        tuesday: {
            total: 0,
            floor: [0,0],
        },
        wednesday: {
            total: 0,
            floor: [0,0],
        },
        thursday: {
            total: 0,
            floor: [0,0],
        },
        friday: {
            total: 0,
            floor: [0,0],
        },
    }

    // initial list
    let data: Array<DayListItem> = []

    // only parse registrations if not empty
    if (registrations && registrations.length > 0) {
        // sum up registrations pr. day and floor
        registrations.map((r: Registration) => {
            Object.keys(days).forEach(key => days[key].total = days[key].total + (r.days[key] ? 1 : 0));
            Object.keys(days).forEach(key => days[key].floor[0] = days[key].floor[0] + (r.days[key] && r.user?.floor == 0 ? 1 : 0));
            Object.keys(days).forEach(key => days[key].floor[1] = days[key].floor[1] + (r.days[key] && r.user?.floor == 1 ? 1 : 0));
        })
        // convert to array with: day, total
        Object.keys(days).forEach((key, i) => {
            let date = moment(selectedWeek).add(i,'day')
            let title = moment(date).format('dddd')
            data.push({
                key: key,
                date: date,
                day: title.charAt(0).toUpperCase() + title.slice(1),
                floor: days[key].floor,
                total: days[key].total,
            })
        })
    }

    // setup table columns
    const week = moment(selectedWeek).week()
    console.log(week, (week % 2))
    const columns = [
        {
            title: 'Dag',
            key: 'day',
            render: (text: string, record: DayListItem) => (
                <Tooltip title={moment(record.date).format('D. MMMM')}>
                    <Text>{record.day}</Text>
                </Tooltip>
            ),
        },
        {
            title: (
                <Space>
                    <Text>Stuen</Text>
                    <Text type="secondary">({week % 2 ? '12:00' : '12:30'})</Text>
                </Space>
            ),
            key: 'floor0',
            render: (text: string, record: DayListItem, index: number) => (
                <Text>{record.floor[0]}</Text>
            ),
        },
        {
            title: (
                <Space>
                    <Text>1. sal</Text>
                    <Text type="secondary">({week % 2 ? '12:30' : '12:00'})</Text>
                </Space>
            ),
            key: 'floor1',
            render: (text: string, record: DayListItem, index: number) => (
                <Text>{record.floor[1]}</Text>
            ),
        },
        {
            title: 'Total',
            key: 'total',
            render: (text: string, record: DayListItem, index: number) => (
                <Row justify="space-between">
                    <Col>
                        <Text>{record.total}</Text>
                    </Col>
                    <Col>
                        {selectedIndex == index && <DoubleRightOutlined />}
                    </Col>
                </Row>
            ),
        },
    ]

    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            locale={{emptyText: 'Ingen tilmeldinger'}}
            pagination={false}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => onClick ? onClick(record, rowIndex) : {},
                }
            }}
        />
    )
}