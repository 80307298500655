import React, { useState, useEffect, createContext, PropsWithChildren } from "react";
import { auth, db } from "~/services/firebase";


interface AuthContextProps {
    user: firebase.User | null
    setUser: any
    isAuthenticated: boolean
    isAdmin: boolean
    isLoaded: boolean
}

export const AuthContext = createContext<Partial<AuthContextProps>>({});

export const AuthProvider: React.FC<PropsWithChildren<any>> = (props) => {
    const [user, setUser] = useState(null as firebase.User | null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        auth.onAuthStateChanged((user: any) => {
            if (user) {
                setUser(user)
                // check if user exists in firebase and isAdmin
                db.collection("users").doc(user.uid).get().then(function(doc) {
                    if (doc.exists) {
                        const data = doc.data()
                        setIsAdmin(data?.isAdmin || false)
                    }
                    setIsLoaded(true)
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                    setUser(null)
                    setIsAdmin(false)
                    setIsLoaded(false)
                });
            } else {
                setUser(null)
                setIsAdmin(false)
                setIsLoaded(false)
            }
        })
    }, [])

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                isAdmin,
                isLoaded,
                isAuthenticated: (user !== null),
            }}>
            {props.children}
        </AuthContext.Provider>
    )
}