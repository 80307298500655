import React, { useEffect, useState } from "react";
import { Layout, Table, Tag, Space, Typography, Menu, Dropdown, Modal, message, Switch, Button, Row, Col, Avatar, Select } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined, MessageOutlined } from '@ant-design/icons';
import { getUsers, approveUser, activateUser, deactivateUser, updateFloor, User, setLunchplan } from '~/services/users';
import {
    TopNav,
    PoweredBy,
    SendPushModal,
} from "~/components";


const { Header, Footer, Content } = Layout;
const { Text } = Typography
const { confirm } = Modal
const { Option } = Select

export const Users = () => {
    const [users, setUsers] = useState<User[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [showDisabled, setShowDisabled] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [sendModalVisible, setSendModalVisible] = useState(false)
    const [tokens, setTokens] = useState<string[]>([])

    const loadUsers = async () => {
        setIsLoading(true)
        let newUsers = await getUsers()
        setUsers(newUsers)
        setIsLoading(false)
    }

    useEffect(() => {
        loadUsers()
    }, [])

    const columns = [
        {
            title: 'Navn',
            key: 'name',
            sorter: (a: User, b: User) => a.name.localeCompare(b.name),
            render: (text: string, record: User) => {
                return (
                    <Space>
                        <Avatar size={30} src={record.photo || ""} />
                        {record.disabled ? <Text delete type="secondary">{record.name}</Text> : <Text>{record.name}</Text>}
                    </Space>
                )
            },
        },
        {
            title: 'E-mail',
            key: 'email',
            sorter: (a: User, b: User) => a.email.localeCompare(b.email),
            render: (text: string, record: User) => record.disabled ? <Text delete type="secondary">{record.name}</Text> : <Text>{record.email}</Text>,
        },
        {
            title: 'Sal',
            key: 'floor',
            sorter: (a: User, b: User) => (a.floor - b.floor),
            render: (text: string, record: User) => (
                <Select
                    style={{ width: 100 }}
                    defaultValue={record.floor}
                    disabled={record.disabled}
                    onChange={(value) => updateFloor(record.uid, value)}>
                    <Option value={0}>Stuen</Option>
                    <Option value={1}>1. sal</Option>
                </Select>
            )
        },
        {
            title: 'Status',
            key: 'status',
            filters: [
                {
                    text: 'Godkendt',
                    value: 'approved',
                },
                {
                    text: 'Ikke godkendt',
                    value: 'not-approved',
                },
                {
                    text: 'Admin',
                    value: 'is-admin',
                },
                {
                    text: 'Deaktiveret',
                    value: 'disabled',
                },
                {
                    text: 'Frokostordning',
                    value: 'lunchplan',
                },
            ],
            filterMultiple: false,
            onFilter: (value: string, record: User) => {
                if (value == 'approved') return record.approved
                if (value == 'not-approved') return !record.approved && !record.disabled
                if (value == 'is-admin') return record.isAdmin
                if (value == 'disabled') {
                    setShowDisabled(true)
                    return record.disabled
                }
                if (value == 'lunchplan') return record.lunchplan

            },
            sorter: (a: User, b: User) => {
                if (a.approved && !b.approved) return 1
                if (!a.approved && b.approved) return -1
                return a.name.localeCompare(b.name)
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text: string, record: User) => {
                let tags = []
                if (record.disabled) {
                    tags.push(<Tag color='#D57378' key="disabled">Deaktiveret</Tag>)
                    return tags
                }
                if (record.approved) {
                    tags.push(<Tag color='#A6BB75' key="approved">Godkendt</Tag>)
                } else {
                    tags.push(<Tag color='#B8B8BC' key="not-approved">Ikke godkendt</Tag>)
                }
                if (record.isAdmin) {
                    tags.push(<Tag color='#A984AA' key="admin">Admin</Tag>)
                }
                if (record.lunchplan) {
                    tags.push(<Tag color='#D8A55D' key="lunchplan">Frokostordning</Tag>)
                }
                return tags
            }
        },
        {
            title: 'Handling',
            key: 'action',
            align: 'right',
            render: (text: string, record: User) => {
                const onClick = ({ key }) => {
                    if (key === 'approve') {
                        approveUser(record.uid)
                            .then(() => {
                                message.success('Bruger godkendt')
                                loadUsers()
                            })
                            .catch((err) => {
                                console.log(err)
                                message.error('Kunne ikke godkende bruger')
                            })
                    } else if (key === 'activate') {
                        activateUser(record.uid)
                            .then(() => {
                                message.success('Bruger aktiveret')
                                loadUsers()
                            })
                            .catch((err) => {
                                console.log(err)
                                message.error('Kunne ikke aktivere bruger')
                            })
                    } else if (key === 'deactivate') {
                        confirm({
                            icon: <ExclamationCircleOutlined />,
                            title: 'Bekræft deaktivering',
                            content: 'Vi du deaktivere ' + record.name + '?',
                            okText: 'Ja',
                            okButtonProps: { danger: true },
                            onOk() {
                                deactivateUser(record.uid)
                                    .then(() => {
                                        message.success('Bruger deaktiveret')
                                        loadUsers()
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        message.error('Kunne ikke deaktivere bruger')
                                    })
                            },
                        })
                    } else if (key === 'sendpush') {
                        setTokens(record.tokens)
                        setSendModalVisible(true)
                    } else if (key === 'lunchplan') {
                        setLunchplan(record.uid, !record.lunchplan)
                            .then(() => {
                                message.success('Frokostordning ' + record.lunchplan ? 'deaktiveret' : 'aktiveret')
                                loadUsers()
                            })
                            .catch((err) => {
                                console.log(err)
                                message.error('Kunne ikke ændre frokostordning')
                            })
                    }
                }
                const menu = () => {
                    let items = []
                    if (record.tokens.length > 0) {
                        items.push(<Menu.Item key="sendpush">Send push</Menu.Item>)
                    }
                    if (record.disabled) {
                        items.push(<Menu.Item key="activate">Aktiver</Menu.Item>)
                    } else {
                        if (!record.approved) items.push(<Menu.Item key="approve">Godkend</Menu.Item>)
                        items.push(<Menu.Item key="lunchplan">Frokostordning</Menu.Item>)
                        if (!record.isAdmin) items.push(<Menu.Item key="deactivate" danger>Deaktiver</Menu.Item>)
                    }
                    return (
                        <Menu onClick={onClick}>
                            {items}
                        </Menu>
                    )
                }
                return (
                    <Dropdown overlay={menu}>
                        <EllipsisOutlined />
                    </Dropdown>
                )
            },
        },
    ]

    const sendPushToMultiple = () => {
        let selectedUsers = selectedRowKeys.map(k => users.find(u => u.key == k))
        let initialTokens: string[] = []
        let selectedTokens: string[] = selectedUsers.reduce((a, b) => a.concat(b.tokens), initialTokens)
        setTokens(selectedTokens)
        setSendModalVisible(true)
    }

    const rowSelection = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: string[], selectedRows: User[]) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: record => ({
            disabled: (record.disabled || record.tokens.length == 0),
        }),
    }

    return (
        <Layout>
            <Header>
                <TopNav />
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <div id="users">
                    <Table
                        loading={isLoading}
                        columns={columns}
                        dataSource={users.filter(u => !u.disabled || showDisabled)}
                        rowSelection={rowSelection}
                        footer={() => (
                            <Row>
                                <Col span={12}>
                                    <Button
                                        type="primary"
                                        shape="round"
                                        icon={<MessageOutlined />}
                                        disabled={selectedRowKeys.length == 0}
                                        onClick={sendPushToMultiple}>
                                        Send push
                                    </Button>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Space>
                                        <Switch
                                            loading={isLoading}
                                            checked={showDisabled}
                                            onChange={setShowDisabled}
                                        />
                                        <Text type="secondary">Vis deaktiverede</Text>
                                    </Space>
                                </Col>
                            </Row>
                        )}
                        locale={{ emptyText: 'Ingen brugere' }}
                    />
                    <SendPushModal
                        visible={sendModalVisible}
                        setVisible={setSendModalVisible}
                        tokens={tokens}
                    />
                </div>
            </Content>
            <Footer>
                <PoweredBy />
            </Footer>
        </Layout>
    )
}